import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import { StaticImage } from "gatsby-plugin-image"

import BtnWrap from "../components/btn-wrap"
import ImgCon from "../components/img-con"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Geografické předpeklí studijních materiálů"
    description="Zeměpisný portál zaměřený na studijní materiály v oblasti geografie, GIS a dalších oborů. Studovat lze například zápisky z biogeografie, hydrologie či geodézie. Blog pak nabízí geografické zajímavosti či postřehy." />
    <h1>Geografické předpeklí studijních materiálů</h1>
    <p>Páteří stránky Zeměpisec.cz jsou bezesporu studijní materiály, které jsem tu a tam sepisovala během studia. Majoritu návštěv tvoří studentstvo, které hledá zejména před zkouškami každou drobnost připravující je na inferno nachystané zkoušejícími. Mluvit tak o předpeklí je zcela na místě. Alespoň je však zdarma a bez reklam. Hurá? Doplňkem je pak blog, kde sporadicky přispívám s nějakou tou geografickou zajímavostí.</p>
    <hr />
    <ImgCon>
    <StaticImage
      src="../images/misa-vojtechovska.jpg"
      alt="Míša Vojtěchovská - autorka projektu Zeměpisec.cz"
      placeholder="dominantColor"
      layout="constrained"
      width={600}
      height={600}
      objectPosition="center center"
      formats={["auto", "webp", "avif"]}
    />
    <div><h2>O autorce projektu Zeměpisec.cz</h2>
    <p>Ahoj! Míša Vojtěchovská jméno mé. Studium geografie a geoinformatiky mě přivedlo do řady zajímavých situací. Nemluvím teď o pocitu zoufalství a existenční tísni během zkouškového, to ne! Mluvím například o skvělé zkušenosti na stáži ČHMÚ, kdy jsem si třeba zkoušela měřit průtoky řek. Nebo když jsme na Erasmu v Norsku prováděli měření na ledovci. Hezky s lany, mačkami. Krom toho jsem si ale vytvořila pomocí GIS pěknou řádku map a prostorových analýz, což mě přivedlo k vývoji webů.</p>
    <BtnWrap><a className="a-btn" href="https://www.linkedin.com/in/vojtechovskych/">O mně profesně</a>
    <a className="a-btn inv" href="https://www.instagram.com/misa.vojte">Nebo osobněji</a></BtnWrap></div></ImgCon>
    <hr />
    <h2>Blog s geografickými zajímavostmi</h2>
    <div className="bl-li">
      <article>
        <StaticImage
          src="../images/blog/1.jpg"
          alt=""
          placeholder="dominantColor"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/tropicke-cyklony-vlivem-klimaticke-zmeny-meni-mista-vyskytu/">Tropické cyklóny vlivem klimatické změny mění místa výskytu</Link></h3>
        <p>22. října, 2020</p>
        </div>
      </article>
      <article>
        <StaticImage
        src="../images/blog/2.jpg"
        alt=""
        placeholder="dominantColor"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/zajem-koronavirus-google-casosberna-mapa-grafy-data/">Vyhledávání koronaviru na Google: Časosběrná mapa, grafy a data</Link></h3>
        <p>27. března, 2020</p>
        </div>
      </article>
      <article>
        <StaticImage
        src="../images/blog/3.jpg"
        alt=""
        placeholder="dominantColor"
        layout="constrained"
        formats={["auto", "webp", "avif"]}
        />
        <div>
        <h3><Link to="/pocet-cajoven-v-cr-a-krajich/">Čajovnová velmoc Česko? Počet čajoven v ČR a krajích</Link></h3>
        <p>18. února, 2020</p>
        </div>
      </article>
    </div>
    <p>Vidíte zjevení pouze posledních tří článků. <Link to="/blog/">Klikněte pro zobrazení všech článků na blogu</Link>.</p>
    <hr />
    <h2>Studijní materiály zdarma</h2>
    <br />
    <ImgCon>
    <StaticImage
    src="../images/biogeografie.jpg"
    alt="Studijní materiály biogeografie"
    placeholder="dominantColor"
    layout="constrained"
    width={600}
    height={600}
    formats={["auto", "webp", "avif"]}
    />
    <div><h3>Biogeografie</h3>
    <p>Studijní materiály biogeografie se zabývají studiem rozšíření organismů v prostoru a čase. Mezi hlavní lákadla patří biologické invaze, teorie ostrovní biogeografie aj.</p>
    <Link className="a-btn" to="/biogeografie/">Začtěte se</Link>
    </div></ImgCon>
    <br />
    <ImgCon>
    <StaticImage
    src="../images/vodni-prehrada.jpg"
    alt="Studijní materiály hydrologie"
    placeholder="dominantColor"
    layout="constrained"
    width={600}
    height={600}
    formats={["auto", "webp", "avif"]}
    />
    <div><h3>Hydrologie</h3>
    <p>Studijní materiály o vodě, bouřlivé i klidné. O povodních, řekách, ale také světovém oceánu či podzemní vodě.</p>
    <Link className="a-btn" to="/hydrologie/">Začtěte se</Link>
    </div></ImgCon>
    <br />
    <ImgCon>
    <StaticImage
    src="../images/ekologie_zp.jpg"
    alt="Studijní materiály Ekologie a ŽP"
    placeholder="dominantColor"
    layout="constrained"
    width={600}
    height={600}
    formats={["auto", "webp", "avif"]}
    />
    <div><h3>Ekologie a životní prostředí</h3>
    <p>Zápisky o vztazích organismů k okolí a k sobě navzájem. Zaměření na životní prostředí České republiky a jeho historický vývoj.</p>
    <Link className="a-btn" to="/ekologie-zivotni-prostredi/">Začtěte se</Link>
    </div></ImgCon>
    <br />
    <p>V záloze číhá více zápisků z geografických a příbuzných oblastí. <Link to="/studijni-materialy/">Klikněte pro zobrazení všech studijních materiálů</Link>.</p>
    <hr />

  </Layout>
)
